import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Home"/>
        <section className="page-section bg-primary" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">A Keen Eye for Structures</h2>
                <hr className="divider light my-4"/>
                <p className="text-white mb-4">Ryan provides realtors in Bentonville, AR and surrounding areas with images that bring out the best in a house so you can make the most of your marketing efforts.</p>
                <a className="btn btn-light btn-xl js-scroll-trigger" href="#portfolio"
                   onClick={Scroller.handleAnchorScroll}>See My Work</a>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="page-section" id="services">
          <div className="container">
            <h2 className="text-center mt-0">At Your Service</h2>
            <hr className="divider my-4"/>
            <div className="row">
              <div className="col-lg-4 col-md-4 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-camera text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Photography</h3>
                  <p className="text-muted mb-0">Get that house shot to sell and show to your next client.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-laptop-code text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Virtual Tours</h3>
                  <p className="text-muted mb-0">Put together an experience for home buyers.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-atom text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Twilight</h3>
                  <p className="text-muted mb-0">Make your listing stand out with magic hour.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-globe text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Websites</h3>
                  <p className="text-muted mb-0">Get a dedicated custom website built with all the details!</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="portfolio">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/portfolio/fullsize/1.jpg" onClick={this.handlePortfolioClick.bind(this, 0)}>
                  <Img fluid={this.props.data.images.edges[0].node.childImageSharp.fluid}/>
                  <div className="portfolio-box-caption">
                    {/* <div className="project-category text-white-50">
                      Category
                    </div> */}
                    <div className="project-name">
                      Front Exterior
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/portfolio/fullsize/2.jpg" onClick={this.handlePortfolioClick.bind(this, 1)}>
                  <Img fluid={this.props.data.images.edges[1].node.childImageSharp.fluid}/>
                  <div className="portfolio-box-caption">
                    {/* <div className="project-category text-white-50">
                      Category
                    </div> */}
                    <div className="project-name">
                      Living Space
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/portfolio/fullsize/3.jpg" onClick={this.handlePortfolioClick.bind(this, 2)}>
                  <Img fluid={this.props.data.images.edges[2].node.childImageSharp.fluid}/>
                  <div className="portfolio-box-caption">
                    {/* <div className="project-category text-white-50">
                      Category
                    </div> */}
                    <div className="project-name">
                      Kitchen
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="images/portfolio/fullsize/4.jpg" onClick={this.handlePortfolioClick.bind(this, 3)}>
                  <Img fluid={this.props.data.images.edges[3].node.childImageSharp.fluid}/>
                  <div className="portfolio-box-caption">
                    {/* <div className="project-category text-white-50">
                      Category
                    </div> */}
                    <div className="project-name">
                      Master Bedroom
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/portfolio/fullsize/5.jpg" onClick={this.handlePortfolioClick.bind(this, 4)}>
                  <Img fluid={this.props.data.images.edges[4].node.childImageSharp.fluid}/>
                  <div className="portfolio-box-caption">
                    {/* <div className="project-category text-white-50">
                      Category
                    </div> */}
                    <div className="project-name">
                      Master Bathroom
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a className="portfolio-box" href="img/portfolio/fullsize/6.jpg" onClick={this.handlePortfolioClick.bind(this, 5)}>
                  <Img fluid={this.props.data.images.edges[5].node.childImageSharp.fluid}/>
                  <div className="portfolio-box-caption p-3">
                    {/* <div className="project-category text-white-50">
                      Category
                    </div> */}
                    <div className="project-name">
                      Backyard / Patio
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="page-section bg-dark text-white">
          <div className="container text-center">
            <h2 className="mb-4">Book Your House Shoot Today!</h2>
            <a className="btn btn-light btn-xl" href="https://startbootstrap.com/themes/creative/">Book Now!</a>
          </div>
        </section> */}

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Let's Get In Touch!</h2>
                <hr className="divider my-4"/>
                <p className="text-muted mb-5">Have a property to market and need some high class images to get rolling?</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <a href="tel:1-651-285-6434">
                <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                <div>+1 (651) 285-6434</div>
                </a>
              </div>
              <div className="col-lg-4 mr-auto text-center">
                <a href="mailto:ryan@ryanshootshouses.com?Subject=I%20Have%20a%20Property%20I%20Would%20Like%20Shot" target="_top">
                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                <div>ryan@ryanshootshouses.com</div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <PortfolioModal show={this.state.modalShow} onHide={() => this.setModal(false, 0)}>
          <PortfolioCarousel images={this.props.data.images.edges} current={this.state.modalCurrent}/>
        </PortfolioModal>
      </Layout>
    )
  }
}
export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "portfolio/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`